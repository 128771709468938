import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/seo"

const NasiPsi = () => {
  return (
    <Layout>
      <Seo title="BSP" />
      <h1 className="post-title">Naši psi</h1>
      <h3>Arga Josefina z Argody</h3>
      <p>
        Arga se narodila 22. 4. 2017 a od té doby nám dělá jenom radost. Kdo by čekal typického klidného berňáčka, mohl by se trochu zarazit. Arga je energická, sportovně stavěná fenka, která se nebojí žádného dobrodružství. Její hlavní doménou je záchranářský výcvik, a to především vyhledávání ztracených osob v sutinách a v plochách. Okrajově se také věnuje stopování a vyhledání v lavinách. Jednou týdně také chodí ráda na cvičák, kde se jí podařilo splnit několik zkoušek. Mezi Argy nejcennější zkoušky patří ta canisterapeutická. Canisterapii si Arga přinesla v rodokmenu z matčiny strany, a přesto, že je trošku energičtější, než je u bernských salašníků zvykem, si s klienty velmi dobře rozumí.
      </p>
      <p><b>Zkoušky:</b> ZOP, ZPU1, BH-VT, ZZO, canisterapeutické</p>
    </Layout>
  )
}

export default NasiPsi
